/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_content_delivery_bucket": "sjerat.de-dev",
    "aws_content_delivery_bucket_region": "eu-central-1",
    //"aws_content_delivery_url": "https://ddkf2d6bvfjb1.cloudfront.net",
    "aws_content_delivery_url": "https://sjerat.de",
    //"aws_cognito_identity_pool_id": "eu-central-1:38eddc79-eb77-44eb-99e6-c6e9578ee808",
    "aws_cognito_identity_pool_id": "eu-central-1:8a24a001-7256-43bc-88dd-20c87fd86df3",
    "aws_cognito_region": "eu-central-1",
    //"aws_user_pools_id": "eu-central-1_8bEt0foWd",
    "aws_user_pools_id": "eu-central-1_PrkwB3NiI",
    //"aws_user_pools_web_client_id": "2a2n53ei4s7n15n9p12fc51r7f",
    "aws_user_pools_web_client_id": "3hisfu5457oiklvnmk2dsrbdas",
    "oauth": {}
};


export default awsmobile;
