import { Injectable } from '@angular/core';
import { Observable, of, from } from 'rxjs';
import { tap, delay, map } from 'rxjs/operators';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

//  isLoggedIn: boolean = false;
//  isLoginRunning: boolean = false;
  public redirectUrl: string;

  constructor() { }

  isLoggedIn(): Observable<any> {
    return from(Auth.currentAuthenticatedUser());
  }

  login(user: string, password: string): Observable<any> {
    return from(Auth.signIn(user, password));
  }

  logout(): void {
    Auth.signOut();
  }

}
