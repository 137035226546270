<ons-page>
  <ons-toolbar>
    <app-logout></app-logout>
    <div class="center">
      Shoppinglist
    </div>
    <div class="background">
    </div>
  </ons-toolbar>
  <div class="content">
    bla bla
  </div>
</ons-page>
