import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { OnsenModule, NgModule, CUSTOM_ELEMENTS_SCHEMA } from 'ngx-onsenui';
import { Observable, of } from 'rxjs';
import { map, catchError, first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(public authService: AuthService, public router: Router) { }

  loginForm = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required)
  });

  message: string = '';

  ngOnInit(): void {
    if (!this.authService.redirectUrl) {
      this.authService.redirectUrl='/';
    }
    //console.log('login.component -> OnInit + redirectUrl', redirectUrl);
  }

  onSubmit() {
    console.log("login triggered");
    //console.log(this.loginForm.value);
    //console.log("Hello " + this.loginForm.value.username);
    const email = this.loginForm.value.username, password = this.loginForm.value.password;
    //console.log("fire signIn");

    this.authService.login(email, password)
    .pipe(
      catchError( err => {
        //console.log('catchError: ', err);
        this.message = err.code + ': ' + err.message;
        return of(false);
      })
    )
    .subscribe(
      data => {
        //console.log('login: ', data);
        if (data.username) {
          const redirectUrl = this.authService.redirectUrl;
          this.router.navigate([redirectUrl]);
        }
      }
    );
  }
}
