<ons-page>
  <ons-toolbar>
    <div class="center">
      Login
    </div>
    <div class="background">
    </div>
  </ons-toolbar>
  <div>
    {{ message }}
  </div>
  <div>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div style="text-align: center; margin-top: 100px;">
        <p>
          <ons-input type="text" modifier="underbar" placeholder="Username" formControlName="username"></ons-input>
        </p>
        <p>
          <ons-input type="password" modifier="underbar" placeholder="Password" formControlName="password"></ons-input>
        </p>
        <p style="margin-top: 30px; padding: 10px">
          <ons-button type="submit" (click)="onSubmit()" [disabled]="!loginForm.valid" modifier="large">Submit</ons-button>
        </p>
      </div>
    </form>

  </div>
</ons-page>
