import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { MainPageComponent } from './main-page/main-page.component';

import { AuthService } from './services/auth.service';
import { AuthGuardGuard as AuthGuard } from './services/auth-guard.guard';

const routes: Routes = [
  { path: '', component: MainPageComponent, canActivate: [AuthGuard]
  },
  { path: 'test', component: MainPageComponent },
  { path: 'login', component: LoginComponent },
  { path: '**', redirectTo: 'test' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
