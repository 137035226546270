import { BrowserModule } from '@angular/platform-browser';
import { OnsenModule } from 'ngx-onsenui';
import { NgModule } from '@angular/core';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './logout/logout.component';
import { MainPageComponent } from './main-page/main-page.component';

//import { AmplifyAngularModule, AmplifyService } from 'aws-amplify-angular';
import { AuthGuardGuard } from './services/auth-guard.guard';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    MainPageComponent
  ],
  imports: [
    BrowserModule,
    OnsenModule,
//    AmplifyAngularModule,
    ReactiveFormsModule,
    AppRoutingModule
  ],
  providers: [
//    AmplifyService,
    AuthGuardGuard
  ],
  schemas: [
      CUSTOM_ELEMENTS_SCHEMA,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
